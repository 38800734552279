import React from "react"
import SEO from "../components/seo"

import List from "../layouts/list"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Container from "../layouts/container"
import Breadcrumbs from '../components/chrome/breadcrumbs'

import Changelog from "../components/changelog"
import Entry from "../components/changelog-entry"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageChangelog = () => {
    return (
        <List>
            <SEO
                title="Changelog"
                description="List of changes and updates to mood-design.net"
            />
            <PageHeader headerText="Keeping track of things" />

            <Main>
                <Container>
                    <Breadcrumbs path="/changelog" title="Changelog" />
                </Container>

                <div className="row">
                    <div className="col--16">
                        <Container>
                            <h1 className="h1">Changelog</h1>
                            <Changelog>
                                <Entry
                                    tagName="li"
                                    date="2021-02-04"
                                    content="Simplified active state of navigation items; added different navigation pattern for mobiles; bugfixes"
                                />
                                <Entry
                                    tagName="li"
                                    date="2021-12-06"
                                    content="Upgrade to Gatsby V4; bugfixes; scroll indicator; new posts"
                                />
                                <Entry
                                    tagName="li"
                                    date="2020-08-11"
                                    content="Improved breadcrumb component; theme change; updated logo with small animation; content update"
                                />
                                <Entry
                                    tagName="li"
                                    date="2019-11-10"
                                    content="CSS theme fixes; Scotland post update; added lightbox; code and data refactoring for gallery functionality"
                                />
                                <Entry
                                    tagName="li"
                                    date="2019-11-04"
                                    content="CSS fixes and stylistic changes; content and markup bugfixes; added changelog; content update"
                                />
                            </Changelog>
                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </List>
    )
}

export default PageChangelog