import React from "react"

const ChangelogEntry = (props) => {

    const { date, content } = props;

    return (
        <li className="changelog__entry">
            <p className="changelog__date">Date: {date}</p>
            <p className="changelog__description">{content}</p>
        </li>
    );
};

export default ChangelogEntry